import React from "react";
import { useTranslation } from "react-i18next";

const Explicitconsent = () => {
  const { t } = useTranslation();

  return (
    <div class="section margin wf-section">
      <div class="container">
        <div class="center-container bottom-margin">
          <div class="text">{t("explicitConsent.lastUpdate")}</div>
          <h1 class="h1">{t("explicitConsent.heading")}</h1>
        </div>
        <div class="rich-text w-richtext">
          <p>{t("explicitConsent.intro")}</p>
          <h3>{t("explicitConsent.consent.title")}</h3>
          <p>{t("explicitConsent.consent.dataCollection")}</p>
          <ul role="list">
            <li>{t("explicitConsent.consent.listOne")}</li>
            <li>{t("explicitConsent.consent.listTwo")}</li>
          </ul>
          <br />
          <h3>{t("explicitConsent.infoCollection.title")}</h3>
          <p>{t("explicitConsent.infoCollection.whatWeCollect")}</p>
          <ul role="list">
            <li>{t("explicitConsent.infoCollection.listOne")}</li>
            <li>{t("explicitConsent.infoCollection.listTwo")}</li>
            <li>{t("explicitConsent.infoCollection.listThree")}</li>
          </ul>
          <p>{t("explicitConsent.infoCollection.weDontSell")}</p>
          <br />
          <h3>{t("explicitConsent.yourRights.title")}</h3>
          <p>{t("explicitConsent.yourRights.compliance")}</p>
          <ul role="list">
            <li>{t("explicitConsent.yourRights.listOne")}</li>
            <li>{t("explicitConsent.yourRights.listTwo")}</li>
            <li>{t("explicitConsent.yourRights.listThree")}</li>
            <li>{t("explicitConsent.yourRights.listFour")}</li>
            <li>{t("explicitConsent.yourRights.listFive")}</li>
            <li>{t("explicitConsent.yourRights.listSix")}</li>
          </ul>
          <br />
          <br />
          <h3>{t("explicitConsent.contact.title")}</h3>
          <p>{t("explicitConsent.contact.description")}</p>
          <br />
          <br />
          <p>{t("explicitConsent.contact.contactName")}</p>
          <p>{t("explicitConsent.contact.contactEmail")}</p>
          <p>{t("explicitConsent.contact.contactAddress")}</p>
          <p>{t("explicitConsent.lastUpdate")}</p>
        </div>
      </div>
    </div>
  );
};

export default Explicitconsent;
