import * as React from 'react';
import PropTypes from 'prop-types';

function MicroLayout({ children }) {
  if (children) {
    return <div className="micro">{children}</div>;
  }
  return <div />;
}

MicroLayout.propTypes = {
  children: PropTypes.object.isRequired, // eslint-disable-line
};

export default MicroLayout;
