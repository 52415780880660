import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translations from "./translations";

i18n.use(LanguageDetector).init({
  resources: translations,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
