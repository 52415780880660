import * as React from "react";

import "./404.scss";

function ErrorPage() {
  return (
    <>
      <section id="error-container">
        <span>404 ERROR</span>
        <h2>
          <strong>We can’t find the page</strong> <br /> you are looking for.
        </h2>
        <p>
          <a className="primary-cta-button" href="/">
            BACK TO HOME
          </a>
        </p>
        <div className="error-img-left">
          <img
            src="https://imagedelivery.net/m_TmrpCr2ia-yFj8fl_8Zw/6f1e6763-aabf-49fa-c6a8-b108f4b77100/avatar"
            alt=""
          />
        </div>
        <div className="error-img-right">
          <img
            src="https://imagedelivery.net/m_TmrpCr2ia-yFj8fl_8Zw/c8f9090d-9d35-4b56-61e4-690ba97c0000/avatar"
            alt=""
          />
        </div>
      </section>
    </>
  );
}

export default ErrorPage;
