import * as React from "react";
import { Link } from "gatsby";

export default (props) => {
  const { target, href, children } = props;
  const internal = target !== "_blank" && /^\/(?!\/)/.test(href);
  if (internal) {
    let link = props.href;
    const timestamp = Math.floor(Date.now() / 1000);
    if (link && link.charAt(link.length - 1) !== "/") {
      link += "/";
    }

    if (link) {
      link += "?v=" + timestamp;
    }
    if (!link) {
      link = "javascript:void(0);";
    }
    return (
      <Link
        rel="canonical"
        activeClassName="active-link"
        {...props}
        to={href}
      />
    );
  }
  return <a {...props}>{children}</a>;
};
