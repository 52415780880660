import * as React from "react";
import PropTypes from "prop-types";
import { I18nextProvider } from "react-i18next";

import SEO from "../components/SEO/SEO";
import Micro from "./Micro";
import ContentLayout from "./ContentLayout";
import Menu from "../components/Menu/Menu";
import Footer from "../components/Footer/Footer";
import "../builder-settings";
import i18n from "../data/i18n/i18next";

function PageLayout({ children }) {
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <SEO />
        <Micro>
          <Menu />
        </Micro>
        <ContentLayout>{children}</ContentLayout>
        <Micro>
          <Footer />
        </Micro>
      </I18nextProvider>
    </>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired, // eslint-disable-line
};

export default PageLayout;
