const WebpageSchema = (name = '', description = '', publisher = '') =>
  JSON.stringify({
    '@context': 'http://schema.org/',
    '@type': 'WebPage',
    name,
    description,
    publisher,
  });

export default WebpageSchema;
