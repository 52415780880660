import * as React from 'react';
import { builder, BuilderComponent } from '@builder.io/react'
import { graphql } from 'gatsby'
import SEO from "../components/SEO/SEO";

builder.init('8614bc239f3e47699658492a9481ddea')

const MyComponent = (props) => {
  const content = props.data?.allBuilderModels.onePage?.content;

  return (
  <>
  <SEO/>
  <BuilderComponent
    content={content}
    model="page" />
  </>
  )
}

export default MyComponent;      

export const query = graphql`
  query($path: String!) {
    allBuilderModels {
      onePage(
        target: { urlPath: $path }
        options: { cachebust: true }
      ) { content }
    }
  }
`

// Register your components for use in the visual editor!
// https://www.builder.io/blog/drag-drop-react
const Heading = props => (
  <h1 className="my-heading">{props.title}</h1>
)