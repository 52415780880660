import React from "react";
import { useTranslation } from "react-i18next";

const Privacypolicy = () => {
  const { t } = useTranslation();

  return (
    <div class="section margin wf-section">
      <div class="container">
        <div class="center-container bottom-margin">
          <div class="text">{t("privacyPolicy.lastUpdate")}</div>
          <h1 class="h1">{t("privacyPolicy.heading")}</h1>
        </div>
        <div class="rich-text w-richtext">
          <p>{t("privacyPolicy.intro")}</p>
          <h3>{t("privacyPolicy.infoCollection.title")}</h3>
          <p>{t("privacyPolicy.infoCollection.whatWeCollect")}</p>
          <ul role="list">
            <li>{t("privacyPolicy.infoCollection.listOne")}</li>
            <li>{t("privacyPolicy.infoCollection.listTwo")}</li>
            <li>{t("privacyPolicy.infoCollection.listThree")}</li>
          </ul>
          <br />
          <p>{t("privacyPolicy.infoCollection.whyWeCollect")}</p>
          <h3>{t("privacyPolicy.howWeUse.title")}</h3>
          <p>{t("privacyPolicy.howWeUse.usage")}</p>
          <ul role="list">
            <li>{t("privacyPolicy.howWeUse.listOne")}</li>
            <li>{t("privacyPolicy.howWeUse.listTwo")}</li>
          </ul>
          <p>{t("privacyPolicy.howWeUse.weDontSell")}</p>
          <br />
          <h3>{t("privacyPolicy.dataProtection.title")}</h3>
          <p>{t("privacyPolicy.dataProtection.description")}</p>
          <br />
          <h3>{t("privacyPolicy.changes.title")}</h3>
          <p>{t("privacyPolicy.changes.description")}</p>
          <br />
          <h3>{t("privacyPolicy.contact.title")}</h3>
          <p>{t("privacyPolicy.contact.description")}</p>
          <br />
          <br />
          <p>{t("privacyPolicy.contact.contactName")}</p>
          <p>{t("privacyPolicy.contact.contactEmail")}</p>
          <p>{t("privacyPolicy.contact.contactAddress")}</p>
          <p>{t("privacyPolicy.lastUpdate")}</p>
        </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
